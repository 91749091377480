import React from "react";

const SixK = ({
  width = 289,
  height = 60,
}: {
  width?: string | number;
  height?: string | number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 289 60"
  >
    <g
      id="Group_1591"
      data-name="Group 1591"
      transform="translate(-2759 -17887)"
    >
      <rect
        id="Rectangle_177"
        data-name="Rectangle 177"
        width="83"
        height="49"
        rx="10"
        transform="translate(2965 17892)"
        fill="#4d17ce"
      />
      <g
        id="Rectangle_178"
        data-name="Rectangle 178"
        transform="translate(2759 17892)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      >
        <rect width="83" height="49" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="82" height="48" rx="9.5" fill="none" />
      </g>
      <g
        id="Rectangle_179"
        data-name="Rectangle 179"
        transform="translate(2860 17892)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      >
        <rect width="83" height="49" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="82" height="48" rx="9.5" fill="none" />
      </g>
      <text
        id="_6K"
        data-name="6K"
        transform="translate(2770 17932)"
        fill="#fff"
        font-size="42"
        font-family="CodecPro-Bold, Codec Pro"
      >
        <tspan x="0" y="0">
          6K
        </tspan>
      </text>
      <text
        id="_4K"
        data-name="4K"
        transform="translate(2873 17931)"
        fill="#fff"
        font-size="42"
        font-family="CodecPro-Bold, Codec Pro"
      >
        <tspan x="0" y="0">
          4K
        </tspan>
      </text>
      <g id="vr" transform="translate(2670.837 17898.941)">
        <path
          id="Path_2788"
          data-name="Path 2788"
          d="M57.326,19.371a16.678,16.678,0,0,1-5.282,12.267,11.7,11.7,0,0,1-4.674,2.715,14.739,14.739,0,0,1-6.125.628,12.705,12.705,0,0,1-6.094-2.395c-.847-.611-1.673-1.253-2.55-1.816a7.087,7.087,0,0,0-4.8-1.123,7.542,7.542,0,0,0-3.472,1.369c-.847.6-1.659,1.245-2.509,1.84a12,12,0,0,1-3.573,1.661,13.6,13.6,0,0,1-4.508.55A13.234,13.234,0,0,1,3.473,29.64a16.9,16.9,0,0,1-2.7-5.185,16.593,16.593,0,0,1-.715-6.335,21.989,21.989,0,0,1,.62-3.735A20.189,20.189,0,0,1,4.286,6.93.482.482,0,0,0,4.361,6.4a8.189,8.189,0,0,1-.33-1.139,1.7,1.7,0,0,1,.389-1.5,6.117,6.117,0,0,1,2.6-1.87,1.829,1.829,0,0,1,1.543.165c.316.176.614.384.912.588A.3.3,0,0,0,9.8,2.69a28.782,28.782,0,0,1,4.506-1.4c1.248-.271,2.508-.5,3.77-.687a66.065,66.065,0,0,1,9.68-.565c2.019-.006,4.04.019,6.059.087,1.305.044,2.611.162,3.911.3,1.213.132,2.425.3,3.627.52a27.522,27.522,0,0,1,6.983,2.125,13.2,13.2,0,0,1,4.769,3.941A19.93,19.93,0,0,1,56.1,12.53a20.879,20.879,0,0,1,.958,3.659A19.188,19.188,0,0,1,57.326,19.371ZM28.753,4.351v.016c-.384,0-.768-.011-1.151,0q-2.291.079-4.584.173a45.263,45.263,0,0,0-5.742.607,29.767,29.767,0,0,0-4.826,1.21,10.161,10.161,0,0,0-5.526,4.389,17.641,17.641,0,0,0-2.5,7.137,12.4,12.4,0,0,0,.2,4.176,12.572,12.572,0,0,0,1.477,3.7,9.369,9.369,0,0,0,7.183,4.9,8.828,8.828,0,0,0,5.768-1.223c.8-.487,1.534-1.1,2.3-1.654a13.126,13.126,0,0,1,5.229-2.371,10.7,10.7,0,0,1,3.943-.04A12.291,12.291,0,0,1,34.15,26.64,22.553,22.553,0,0,1,37.2,28.7a9.485,9.485,0,0,0,4.588,1.949,9.046,9.046,0,0,0,5.3-.9,9.882,9.882,0,0,0,3.2-2.671,11.636,11.636,0,0,0,2.66-6.436,15.157,15.157,0,0,0-.495-5.324,16.246,16.246,0,0,0-2.168-4.811,9.921,9.921,0,0,0-3.856-3.523,18.522,18.522,0,0,0-2.764-.979A28.676,28.676,0,0,0,38.413,4.9c-1.588-.186-3.183-.319-4.779-.409C32.01,4.4,30.38,4.4,28.753,4.351Z"
          transform="translate(307)"
        />
        <path
          id="Path_2789"
          data-name="Path 2789"
          d="M89.6,38.269c.693.044,1.767.089,2.834.19,1.018.1,2.037.211,3.042.389a30.4,30.4,0,0,1,3.028.69c1.007.289,1.989.668,2.979,1.015.474.167.944.349,1.415.528a3.667,3.667,0,0,1,1.389,1.033.829.829,0,0,1,.179.763.591.591,0,0,1-.6.43,10.353,10.353,0,0,1-1.488-.049c-1.546-.167-3.09-.373-4.636-.535a72.459,72.459,0,0,0-7.328-.355c-3.25-.008-6.5,0-9.75.067-1.654.033-3.31.168-4.958.322-1.413.132-2.818.36-4.227.538a4.916,4.916,0,0,1-1.613.03.633.633,0,0,1-.411-1.085,4.023,4.023,0,0,1,1.5-1.137,24.707,24.707,0,0,1,3.978-1.554,17.08,17.08,0,0,1,3.023-.611c1.619-.16,3.239-.324,4.86-.466,1.367-.121,2.734-.246,4.1-.308C87.681,38.124,88.452,38.216,89.6,38.269Z"
          transform="translate(248.717 -32.073)"
        />
      </g>
    </g>
  </svg>
);

export default SixK;
