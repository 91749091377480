import React from "react";

const LogoBlack = ({ width=657.067, height }: { width?: string | number; height?: string }) => (
  < 
    
  
  svg xmlns="http://www.w3.org/2000/svg" width={width} height="93.025" viewBox="0 0 657.067 93.025">
  <g id="Group_1588" data-name="Group 1588" transform="translate(-2624.6 -18336.975)">
    <g id="Switch" transform="translate(2330.782 18194.275)">
      <g id="Group_1580" data-name="Group 1580" transform="translate(658.409 142.7)">
        <path id="Path_2781" data-name="Path 2781" d="M705.683,205.453c-.051-.38-.134-.751-.2-1.131-.134-.781-.278-1.563-.463-2.324-.113-.432-.237-.853-.37-1.275-.206-.689-.432-1.378-.7-2.046-.164-.422-.34-.833-.514-1.244-.288-.658-.607-1.285-.946-1.912-.206-.38-.4-.761-.627-1.131-.391-.658-.812-1.285-1.254-1.9-.216-.3-.411-.617-.638-.915a30.8,30.8,0,0,0-2.067-2.416c-.051-.051-.082-.1-.134-.154a27.477,27.477,0,0,0-2.5-2.272c-.278-.226-.565-.422-.853-.627-.617-.473-1.244-.925-1.9-1.337-.36-.226-.73-.432-1.1-.648-.617-.36-1.244-.7-1.9-1.008-.514-.247-1.038-.473-1.573-.7-.638-.257-1.285-.483-1.943-.7-.319-.113-.648-.216-.977-.308-.74-.206-1.491-.37-2.262-.524-.391-.072-.771-.175-1.162-.236a27.363,27.363,0,0,0-3.2-.308c-.113,0-.226-.031-.349-.031a31.373,31.373,0,0,0-3.661.134c-.38.031-.761.113-1.131.165-.668.093-1.337.154-2.015.288-.144.031-.267.093-.4.123A32.684,32.684,0,0,0,666,182.441V145.147a2.447,2.447,0,0,0-2.447-2.447H650.847a2.447,2.447,0,0,0-2.447,2.447v87.841a2.446,2.446,0,0,0,2.447,2.447h12.709A2.446,2.446,0,0,0,666,232.989V209.041a10.85,10.85,0,0,1,.226-2.1c.031-.175.072-.339.113-.514a10.764,10.764,0,0,1,.627-1.892c.01-.01.01-.031.021-.051A10.778,10.778,0,0,1,668,202.748c.093-.134.2-.278.3-.411a11.387,11.387,0,0,1,1.285-1.44.273.273,0,0,0,.051-.062,11.467,11.467,0,0,1,1.6-1.2c.144-.093.288-.175.432-.257a10.689,10.689,0,0,1,1.738-.812.548.548,0,0,0,.113-.051,10.75,10.75,0,0,1,2.005-.473c.175-.031.349-.041.524-.062a10.849,10.849,0,0,1,2.005-.041h.164c5.851.514,10.087,5.923,10.087,11.8v23.248a2.446,2.446,0,0,0,2.447,2.447h12.709a2.446,2.446,0,0,0,2.447-2.447V209.041A27.4,27.4,0,0,0,705.683,205.453Z" transform="translate(-648.4 -142.7)"/>
      </g>
      <g id="Group_1581" data-name="Group 1581" transform="translate(293.818 142.731)">
        <path id="Path_2782" data-name="Path 2782" d="M342.188,180.332c-.021-.01-.021-.051-.051-.051H321.4a10.005,10.005,0,0,1-9.871-11.465,10.2,10.2,0,0,1,10.21-8.483h35.926a2.446,2.446,0,0,0,2.447-2.447V145.177a2.446,2.446,0,0,0-2.447-2.447H321.387a27.616,27.616,0,0,0-27,33.222c2.591,12.9,14.333,21.922,27.495,21.922h19.464a9.981,9.981,0,0,1,9.861,11.444,10.215,10.215,0,0,1-10.231,8.5H296.267a2.446,2.446,0,0,0-2.447,2.447v12.709a2.446,2.446,0,0,0,2.447,2.447h44.615c13.058,0,24.739-8.9,27.4-21.685A27.57,27.57,0,0,0,342.188,180.332Z" transform="translate(-293.818 -142.73)"/>
      </g>
      <g id="Group_1582" data-name="Group 1582" transform="translate(386.34 167.758)">
        <path id="Path_2783" data-name="Path 2783" d="M478.767,167.07H466.058a2.446,2.446,0,0,0-2.447,2.447V206a11.171,11.171,0,0,1-11.855,11.136,11.435,11.435,0,0,1-10.437-11.557v-36.05a2.446,2.446,0,0,0-2.447-2.447H426.163a2.446,2.446,0,0,0-2.447,2.447v36.482a11.153,11.153,0,0,1-11.866,11.125A11.446,11.446,0,0,1,401.4,205.567V169.527a2.446,2.446,0,0,0-2.447-2.447H386.247a2.446,2.446,0,0,0-2.447,2.447v36.122c0,13.459,9.11,25.49,22.251,28.389a28.7,28.7,0,0,0,26.569-7.516,27.108,27.108,0,0,0,13.943,7.65,28.8,28.8,0,0,0,34.631-28.163V169.528A2.425,2.425,0,0,0,478.767,167.07Z" transform="translate(-383.8 -167.07)"/>
      </g>
      <g id="Group_1583" data-name="Group 1583" transform="translate(501.183 142.7)">
        <path id="Path_2784" data-name="Path 2784" d="M497.937,158.093h12.709a2.446,2.446,0,0,0,2.447-2.447v-10.5a2.446,2.446,0,0,0-2.447-2.447H497.937a2.446,2.446,0,0,0-2.447,2.447v10.5A2.453,2.453,0,0,0,497.937,158.093Z" transform="translate(-495.49 -142.7)"/>
      </g>
      <g id="Group_1584" data-name="Group 1584" transform="translate(536.214 142.7)">
        <path id="Path_2785" data-name="Path 2785" d="M562.556,161.506H547.163V145.147a2.446,2.446,0,0,0-2.447-2.447H532.007a2.447,2.447,0,0,0-2.447,2.447v61.529A28.784,28.784,0,0,0,555.667,235.3a2.436,2.436,0,0,0,2.632-2.447V220.043a2.4,2.4,0,0,0-1.954-2.385,11.175,11.175,0,0,1-9.192-10.981V179.1h15.393a2.446,2.446,0,0,0,2.447-2.447V163.943A2.436,2.436,0,0,0,562.556,161.506Z" transform="translate(-529.56 -142.7)"/>
      </g>
      <g id="Group_1585" data-name="Group 1585" transform="translate(589.096 161.486)">
        <path id="Path_2786" data-name="Path 2786" d="M616.186,234.91h14.261a2.447,2.447,0,0,0,2.447-2.447V219.754a2.446,2.446,0,0,0-2.447-2.447H617.934a19.431,19.431,0,0,1-18.4-25.541A19.222,19.222,0,0,1,618,178.573h12.462a2.446,2.446,0,0,0,2.447-2.447V163.417a2.446,2.446,0,0,0-2.447-2.447H617.944c-26.209,0-46.116,27.423-32.595,55.072A34.184,34.184,0,0,0,616.186,234.91Z" transform="translate(-580.99 -160.97)"/>
      </g>
      <g id="Group_1586" data-name="Group 1586" transform="translate(501.183 167.748)">
        <path id="Path_2787" data-name="Path 2787" d="M497.937,234.738h12.709a2.446,2.446,0,0,0,2.447-2.447V169.507a2.446,2.446,0,0,0-2.447-2.447H497.937a2.446,2.446,0,0,0-2.447,2.447v62.784A2.446,2.446,0,0,0,497.937,234.738Z" transform="translate(-495.49 -167.06)"/>
      </g>
    </g>
    <rect id="Rectangle_176" data-name="Rectangle 176" width="5" height="93" transform="translate(3084 18337)"/>
    <text id="عربية" transform="translate(3122 18403)" fill="#000" font-size="65" font-family="CodecPro-ExtraBold, Codec Pro" style={{letterSpacing: -3}}><tspan x="0" y="0">عربية</tspan></text>
    <path id="registered-regular" d="M6,1.126A4.878,4.878,0,1,1,1.126,6,4.878,4.878,0,0,1,6,1.126ZM6,12.007A6,6,0,1,0,0,6,6,6,0,0,0,6,12.007ZM3.752,3.565V8.442a.563.563,0,1,0,1.126,0V6.754H6.306l.872,1.921A.563.563,0,1,0,8.2,8.208L7.4,6.449A1.876,1.876,0,0,0,6.379,3H4.315A.561.561,0,0,0,3.752,3.565ZM4.878,5.628v-1.5h1.5a.75.75,0,0,1,0,1.5Z" transform="translate(3269.66 18349.994)"/>
  </g>
</svg>
);

export default LogoBlack;
