const LogoEducation = ({
  width = 657.067,
  height,
}: {
  width?: string | number;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="102.84"
    viewBox="0 0 454 102.84"
  >
    <g
      id="Group_1587"
      data-name="Group 1587"
      transform="translate(-2107 -17758)"
    >
      <g id="Switch" transform="translate(1816.182 17615.301)">
        <g
          id="Group_1580"
          data-name="Group 1580"
          transform="translate(496.685 142.7)"
        >
          <path
            id="Path_2781"
            data-name="Path 2781"
            d="M680.273,177.617c-.029-.212-.074-.418-.109-.629-.074-.435-.154-.87-.257-1.293-.063-.24-.132-.475-.206-.709-.114-.383-.24-.767-.389-1.139-.092-.235-.189-.463-.286-.692-.16-.366-.338-.715-.526-1.064-.114-.212-.223-.423-.349-.629-.217-.366-.452-.715-.7-1.058-.12-.166-.229-.343-.355-.509a17.162,17.162,0,0,0-1.15-1.344c-.029-.029-.046-.057-.074-.086a15.3,15.3,0,0,0-1.39-1.264c-.154-.126-.315-.235-.475-.349-.343-.263-.692-.515-1.058-.744-.2-.126-.406-.24-.612-.36-.343-.2-.692-.389-1.058-.561-.286-.137-.578-.263-.875-.389-.355-.143-.715-.269-1.081-.389-.177-.063-.36-.12-.544-.172-.412-.114-.83-.206-1.259-.292-.217-.04-.429-.1-.647-.132a15.229,15.229,0,0,0-1.779-.172c-.063,0-.126-.017-.195-.017a17.454,17.454,0,0,0-2.037.074c-.212.017-.423.063-.629.092-.372.051-.744.086-1.121.16-.08.017-.149.051-.223.069a18.183,18.183,0,0,0-2.695.8V144.062a1.361,1.361,0,0,0-1.362-1.362h-7.071a1.361,1.361,0,0,0-1.362,1.362v48.877a1.361,1.361,0,0,0,1.362,1.362h7.071a1.361,1.361,0,0,0,1.362-1.362V179.614a6.034,6.034,0,0,1,.126-1.167c.017-.1.04-.189.063-.286a5.983,5.983,0,0,1,.349-1.053c.006-.006.006-.017.011-.029a6,6,0,0,1,.561-.967c.052-.074.109-.154.166-.229a6.331,6.331,0,0,1,.715-.8.152.152,0,0,0,.029-.034,6.381,6.381,0,0,1,.893-.669c.08-.051.16-.1.24-.143a5.947,5.947,0,0,1,.967-.452.3.3,0,0,0,.063-.029,5.981,5.981,0,0,1,1.116-.263c.1-.017.195-.023.292-.034a6.036,6.036,0,0,1,1.116-.023h.091A6.462,6.462,0,0,1,670.6,180v12.936a1.361,1.361,0,0,0,1.362,1.362h7.072a1.361,1.361,0,0,0,1.362-1.362V179.614A15.242,15.242,0,0,0,680.273,177.617Z"
            transform="translate(-648.4 -142.7)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_1581"
          data-name="Group 1581"
          transform="translate(293.818 142.717)"
        >
          <path
            id="Path_2782"
            data-name="Path 2782"
            d="M320.732,163.653c-.011-.006-.011-.029-.029-.029h-11.54a5.567,5.567,0,0,1-5.492-6.379,5.676,5.676,0,0,1,5.681-4.72h19.99a1.361,1.361,0,0,0,1.362-1.362v-7.072a1.361,1.361,0,0,0-1.362-1.362H309.158a15.343,15.343,0,1,0,.275,30.683h10.83a5.553,5.553,0,0,1,5.487,6.368,5.684,5.684,0,0,1-5.693,4.732H295.181a1.361,1.361,0,0,0-1.362,1.362v7.072a1.361,1.361,0,0,0,1.362,1.362h24.825a15.332,15.332,0,0,0,.727-30.655Z"
            transform="translate(-293.818 -142.73)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_1582"
          data-name="Group 1582"
          transform="translate(345.3 156.643)"
        >
          <path
            id="Path_2783"
            data-name="Path 2783"
            d="M436.642,167.07H429.57a1.361,1.361,0,0,0-1.362,1.362v20.3a6.216,6.216,0,0,1-6.6,6.2A6.363,6.363,0,0,1,415.8,188.5V168.437a1.361,1.361,0,0,0-1.362-1.362h-7.072a1.361,1.361,0,0,0-1.362,1.362v20.3a6.206,6.206,0,0,1-6.6,6.19,6.369,6.369,0,0,1-5.813-6.436V168.437a1.361,1.361,0,0,0-1.362-1.362h-7.072a1.361,1.361,0,0,0-1.362,1.362v20.1a15.965,15.965,0,0,0,27.165,11.614,15.083,15.083,0,0,0,7.758,4.257,16.027,16.027,0,0,0,19.269-15.671v-20.3A1.349,1.349,0,0,0,436.642,167.07Z"
            transform="translate(-383.8 -167.07)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_1583"
          data-name="Group 1583"
          transform="translate(409.201 142.7)"
        >
          <path
            id="Path_2784"
            data-name="Path 2784"
            d="M496.852,151.265h7.072a1.361,1.361,0,0,0,1.362-1.362v-5.841a1.361,1.361,0,0,0-1.362-1.362h-7.072a1.361,1.361,0,0,0-1.362,1.362V149.9A1.365,1.365,0,0,0,496.852,151.265Z"
            transform="translate(-495.49 -142.7)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_1584"
          data-name="Group 1584"
          transform="translate(428.693 142.7)"
        >
          <path
            id="Path_2785"
            data-name="Path 2785"
            d="M547.92,153.164h-8.565v-9.1a1.361,1.361,0,0,0-1.362-1.362h-7.072a1.361,1.361,0,0,0-1.362,1.362V178.3a16.016,16.016,0,0,0,14.526,15.928,1.355,1.355,0,0,0,1.465-1.362v-7.129a1.335,1.335,0,0,0-1.087-1.327,6.218,6.218,0,0,1-5.115-6.11V162.953h8.565a1.361,1.361,0,0,0,1.362-1.362V154.52A1.355,1.355,0,0,0,547.92,153.164Z"
            transform="translate(-529.56 -142.7)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_1585"
          data-name="Group 1585"
          transform="translate(458.118 153.153)"
        >
          <path
            id="Path_2786"
            data-name="Path 2786"
            d="M600.574,202.112h7.935a1.361,1.361,0,0,0,1.362-1.362v-7.072a1.361,1.361,0,0,0-1.362-1.362h-6.963a10.812,10.812,0,0,1-10.235-14.212,10.7,10.7,0,0,1,10.27-7.34h6.934a1.361,1.361,0,0,0,1.362-1.362v-7.072a1.361,1.361,0,0,0-1.362-1.362h-6.963c-14.584,0-25.66,15.259-18.136,30.643A19.021,19.021,0,0,0,600.574,202.112Z"
            transform="translate(-580.99 -160.97)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_1586"
          data-name="Group 1586"
          transform="translate(409.201 156.637)"
        >
          <path
            id="Path_2787"
            data-name="Path 2787"
            d="M496.852,204.718h7.072a1.361,1.361,0,0,0,1.362-1.362V168.422a1.361,1.361,0,0,0-1.362-1.362h-7.072a1.361,1.361,0,0,0-1.362,1.362v34.934A1.361,1.361,0,0,0,496.852,204.718Z"
            transform="translate(-495.49 -167.06)"
            fill="#fff"
          />
        </g>
      </g>
      <text
        id="التعلمية"
        data-name="التعلمية"
        transform="translate(2262 17853.84)"
        fill="#fff"
        font-size="29"
        font-weight="normal"
        font-family="CodecPro-Bold, Codec Pro"
      >
        <tspan x="-154.388" y="0">
          التعليمية
        </tspan>
      </text>
      <rect
        id="Rectangle_175"
        data-name="Rectangle 175"
        width="4"
        height="85"
        transform="translate(2377 17758)"
        fill="#fff"
      />
      <text
        id="Browse_By_Country"
        data-name="Browse
By Country"
        transform="translate(2415 17790)"
        fill="#fff"
        font-size="25"
        font-family="CodecPro-Bold, Codec Pro"
        font-weight="normal"
      >
        <tspan x="0" y="0">
          Browse
        </tspan>
        <tspan x="0" y="35">
          By Country
        </tspan>
      </text>
    </g>
  </svg>
);

export default LogoEducation;
